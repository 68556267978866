@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

@import url("https://use.typekit.net/tty6fna.css");
@import url("https://fonts.googleapis.com/css2?family=Merienda&family=Open+Sans&display=swap");

body {
  @apply overflow-x-hidden bg-[#000000] font-open;
}

.menu-items a {
  @apply relative text-white transition-colors duration-300 after:absolute after:left-0 after:-bottom-[2px] after:h-[2px] after:w-0 after:bg-gradient-to-r after:from-brand-alt_dark after:to-brand-main after:transition-all after:duration-300 after:content-[''] hover:after:w-full;
}

@media only screen and (max-width: 1023px) {
  .menu-bg-block {
    border-right: solid;
    border-left: none;
    border-width: 2px;
    border-image: linear-gradient(to bottom, #ff640090, #ff00eb90, #9600ff90) 1
      100%;
  }
}

.slanted-down:before {
  @apply absolute inset-0 h-full w-full bg-brand-alt_t20 content-[''] lg:bg-gradient-to-l lg:from-brand-alt lg:via-[#34150F] lg:to-[#000] lg:mix-blend-color;
}

.slanted-down:after {
  @apply absolute inset-0 h-full w-full origin-top-right skew-y-[4deg] bg-inherit bg-cover bg-no-repeat opacity-50 content-[''] lg:bg-contain lg:bg-right-top lg:opacity-100 2xl:bg-[55%_auto];
  background-image: url("./mission-char.webp");
}

.slanted-up:before {
  @apply absolute inset-0 h-full w-full bg-brand-main_t20 content-[''] lg:bg-gradient-to-r lg:from-brand-main lg:via-[#160925] lg:to-[#000] lg:mix-blend-color;
}

.slanted-up:after {
  @apply absolute inset-0 h-full w-full origin-bottom-left skew-y-[4deg] bg-inherit bg-cover bg-center bg-no-repeat opacity-75 content-[''] sm:bg-left-top lg:bg-contain;
  background-image: url("./updates-peripherals.webp");
}

.slanted-down:hover:after,
.slanted-up:hover:after {
  @apply blur-0;
}

.fade-in-down {
  animation: fadeInDown 1.5s;
}

.fade-in-up {
  animation: fadeInUp 1.5s;
}

.fade-in {
  animation: fadeIn 1.5s;
}

.initialize-nav-items {
  animation: iconNavItems 1200ms;
}

@keyframes fadeInDown {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes iconNavItems {
  0% {
    transform: translateY(-128px);
  }
  50% {
    transform: translateY(-128px);
  }
  100% {
    animation-duration: 300ms;
    transform: translateY(0px);
  }
}
